import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"   transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
 
<path d="M1431 2387 c-14 -7 -123 -115 -244 -242 -121 -126 -254 -266 -296
-310 -86 -89 -111 -122 -111 -146 0 -17 268 -284 455 -454 17 -15 72 -67 122
-116 61 -58 101 -89 115 -89 16 0 115 92 347 325 304 304 324 326 316 349 -6
14 -95 112 -200 219 -104 106 -244 250 -310 318 -143 150 -161 163 -194 146z
m357 -356 l331 -338 -325 -325 -326 -325 -206 201 c-114 110 -264 257 -334
326 l-127 125 322 337 c177 186 325 338 328 338 4 0 155 -152 337 -339z"/>
<path d="M1318 1838 c-81 -84 -141 -155 -135 -157 7 -2 74 -65 151 -141 l138
-137 144 146 144 146 -148 148 -148 148 -146 -153z m277 -15 c63 -65 115 -125
115 -133 0 -18 -222 -240 -240 -240 -14 0 -250 229 -250 242 0 14 232 248 246
248 7 0 65 -53 129 -117z"/>
<path d="M887 1683 c18 -2 50 -2 70 0 21 2 7 4 -32 4 -38 0 -55 -2 -38 -4z"/>
<path d="M740 938 c-25 -13 -57 -69 -81 -140 -11 -33 -10 -40 6 -58 16 -18 30
-20 159 -19 106 1 147 5 164 16 21 13 26 13 41 -1 22 -20 91 -21 116 -2 17 12
23 12 47 0 30 -16 265 -18 284 -3 6 5 24 3 42 -3 25 -10 39 -10 64 0 21 8 36
8 42 2 14 -14 90 -13 138 2 31 9 44 9 59 0 10 -7 52 -12 92 -12 65 0 76 3 97
25 l23 24 19 -24 c28 -35 82 -34 120 2 22 21 28 23 28 10 0 -25 33 -37 105
-37 85 0 105 11 105 55 0 56 -21 139 -38 153 -24 18 -86 15 -112 -6 -23 -18
-24 -18 -42 0 -28 28 -167 26 -193 -2 l-18 -20 -23 21 c-36 34 -92 29 -125
-11 -22 -25 -29 -29 -35 -17 -25 40 -53 46 -233 45 -160 -1 -175 -3 -192 -22
l-19 -20 -25 24 c-32 30 -87 31 -119 1 l-23 -21 -18 20 c-17 18 -31 21 -109
21 -50 0 -113 1 -140 1 -39 1 -53 -4 -67 -21 -21 -26 -26 -26 -47 -1 -22 26
-61 33 -92 18z m89 -80 c6 -35 14 -75 17 -90 l6 -27 -91 0 c-90 0 -91 0 -91
25 0 13 4 24 8 24 5 0 19 29 31 65 l23 65 43 0 42 0 12 -62z m193 28 c-3 -27
-7 -33 -23 -29 -10 3 -19 9 -19 14 0 5 -4 9 -10 9 -5 0 -10 -7 -10 -15 0 -8 7
-15 15 -15 8 0 15 -8 15 -19 0 -11 -6 -18 -16 -17 -9 0 -19 -5 -21 -11 -3 -7
2 -13 11 -13 10 0 16 -9 16 -25 0 -24 -3 -25 -55 -25 -46 0 -55 3 -55 18 0 10
4 22 9 28 12 13 21 83 11 89 -4 3 -6 14 -3 25 5 17 14 20 72 20 l67 0 -4 -34z
m164 12 c-3 -13 -6 -28 -6 -35 0 -6 -9 -9 -20 -6 -11 3 -20 9 -20 14 0 5 -4 9
-10 9 -5 0 -10 -7 -10 -15 0 -8 7 -15 15 -15 8 0 15 -9 15 -20 0 -13 -5 -18
-15 -14 -9 4 -15 0 -15 -10 0 -9 7 -16 16 -16 11 0 14 -5 10 -16 -3 -9 -6 -20
-6 -25 0 -5 -25 -9 -56 -9 -54 0 -56 1 -49 23 19 64 24 95 19 125 l-6 32 72 0
c70 0 72 -1 66 -22z m158 0 c16 -74 26 -131 26 -143 0 -11 -19 -14 -90 -14
-95 0 -104 5 -76 44 7 11 22 46 32 78 l18 57 43 0 c36 0 43 -3 47 -22z m171
-18 c10 -22 22 -40 27 -40 4 0 5 18 2 40 l-7 40 41 0 c40 0 41 -1 32 -25 -5
-14 -12 -54 -16 -90 l-7 -65 -33 0 c-30 0 -35 5 -53 45 -11 25 -23 45 -27 45
-3 0 -3 -20 0 -45 l7 -45 -42 0 -42 0 9 38 c4 20 7 61 7 90 l-1 52 42 0 c40 0
44 -2 61 -40z m270 15 c28 -28 31 -51 11 -99 -16 -39 -55 -56 -127 -56 -27 0
-49 1 -49 3 0 1 7 25 15 52 10 30 13 65 9 88 l-6 37 62 0 c51 0 65 -4 85 -25z
m195 -37 c7 -35 14 -75 17 -90 l5 -27 -91 0 c-82 0 -91 2 -91 19 0 10 4 21 9
24 5 3 19 35 32 71 l23 65 42 0 42 0 12 -62z m230 32 c0 -25 -4 -30 -24 -30
-13 0 -26 6 -29 13 -2 6 -6 -9 -8 -35 -2 -27 -1 -48 3 -48 5 0 8 -11 8 -25 0
-23 -3 -25 -50 -25 -46 0 -50 2 -50 24 0 13 4 26 8 28 5 2 12 23 15 48 3 25 4
39 1 33 -3 -7 -17 -13 -31 -13 -19 0 -24 4 -19 16 3 9 6 22 6 30 0 11 18 14
85 14 l85 0 0 -30z m168 -48 c7 -42 12 -82 12 -89 0 -8 -26 -12 -85 -12 -81 1
-85 2 -85 23 0 12 4 26 8 32 8 8 16 28 41 97 9 24 16 27 54 27 l43 0 12 -78z
m-328 -26 c0 -15 -18 -42 -24 -36 -2 3 -8 23 -12 45 l-7 40 22 -19 c11 -11 21
-24 21 -30z m160 -6 c-14 -34 -30 -40 -30 -11 0 20 29 56 38 47 2 -3 -1 -19
-8 -36z"/>
<path d="M1708 834 c-2 -25 0 -48 4 -50 10 -6 15 6 23 54 5 34 4 42 -8 42 -11
0 -16 -14 -19 -46z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
